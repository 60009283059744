import React from "react"
import * as styles from "./header-nav.module.scss"
export const HeaderNav = () => {
  return (
    <ul className={styles.root}>
      <li className={styles.navItem}>
        <a href="/" title="Trang chủ" className={styles.navLink}>
          <span>{"Trang chủ"}</span>
        </a>
      </li>
      <li className={styles.navItem}>
        <a href="/" title="Sản phẩm" className={styles.navLink}>
          <span>{"Sản phẩm"}</span>
        </a>
      </li>
      <li className={styles.navItem}>
        <a
          href="/"
          title="Giá tốt đón xuân"
          className={styles.navLinkAnnimation}
        >
          <span>{"Giá tốt đón xuân"}</span>
        </a>
      </li>
      <li className={styles.navItem}>
        <a href="/" title="Quà tặng tết" className={styles.navLink}>
          <span>{"Quà tặng tết"}</span>
        </a>
      </li>
      <li className={styles.navItem}>
        <a href="/" title="Chính sách bảo hành" className={styles.navLink}>
          <span>{"Chính sách bảo hành"}</span>
        </a>
      </li>
      <li className={styles.navItem}>
        <a href="/" title="Tin tức" className={styles.navLink}>
          <span>{"Tin tức"}</span>
        </a>
      </li>
    </ul>
  )
}
