import React from "react"
import * as styles from "./aside-item.module.scss"
import { LocationRoute } from "@bep43vn/pages/page-routing"

export const AsideItem = () => {
  return (
    <aside className={styles.root}>
      <div className={styles.asideTitle}>
        <h2 className={styles.titleHead}>
          <span>Danh mục sản phẩm</span>
        </h2>
      </div>
      <div className={styles.asideContent}>
        <nav>
          <ul className={styles.nav}>
            <li className={styles.navItem}>
              <a href={LocationRoute.DieuHoa} className={styles.navLink}>
                {"Điều hòa"}
              </a>
            </li>
            <li className={styles.navItem}>
              <a href={LocationRoute.MayEpTraiCay} className={styles.navLink}>
                {"Máy ép trái cây"}
              </a>
            </li>
            <li className={styles.navItem}>
              <a href={LocationRoute.MayXaySinhTo} className={styles.navLink}>
                {"Máy xay sinh tố"}
              </a>
            </li>
            <li className={styles.navItem}>
              <a href={LocationRoute.NoiComDien} className={styles.navLink}>
                {"Nồi cơm điện"}
              </a>
            </li>
            <li className={styles.navItem}>
              <a
                href={LocationRoute.BepHutKhoiKhuMui}
                className={styles.navLink}
              >
                {"Bếp - Hút khói - Khử mùi"}
              </a>
            </li>
            <li className={styles.navItem}>
              <a
                href={LocationRoute.NoiThuyTinhVisions}
                className={styles.navLink}
              >
                {"Nồi thủy tinh Visions"}
              </a>
            </li>
            <li className={styles.navItem}>
              <a href={LocationRoute.NoiMenFujihoro} className={styles.navLink}>
                {"Nồi men Fujihoro"}
              </a>
            </li>
            <li className={styles.navItem}>
              <a
                href={LocationRoute.NoiChienKhongDau}
                className={styles.navLink}
              >
                {"Nồi chiên không dầu"}
              </a>
            </li>
            <li className={styles.navItem}>
              <a href={LocationRoute.Quat} className={styles.navLink}>
                {"Quạt"}
              </a>
            </li>
            <li className={styles.navItem}>
              <a href={LocationRoute.GiaDungKhac} className={styles.navLink}>
                {"Gia dụng khác"}
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </aside>
  )
}
