import React from "react"
import { TopNavigationItem } from "./top-navigation-item"
import * as styles from "./top-navigation-scene.module.scss"

export const TopNavigationScene = () => {
  return (
    <nav className={styles.root}>
      <div className={styles.container}>
        <TopNavigationItem></TopNavigationItem>
      </div>
    </nav>
  )
}
