// extracted by mini-css-extract-plugin
export var root = "product-card-module--root--GvFvO";
export var container = "product-card-module--container--z6Od2";
export var card = "product-card-module--card--byqa7";
export var thumbnail = "product-card-module--thumbnail--FP+Bx";
export var thumbnailImage = "product-card-module--thumbnailImage--N6UXI";
export var productInfor = "product-card-module--productInfor--hmZ7Y";
export var productName = "product-card-module--productName--2qL5H";
export var priceBox = "product-card-module--priceBox--4t-EI";
export var comparePrice = "product-card-module--comparePrice--lOvfC";
export var productSale = "product-card-module--productSale--deC6i";