import React from "react"
import * as styles from "./footer-scene.module.scss"

export const FooterScene = () => {
  return (
    <div className={styles.root}>
      <div className={styles.inforSection}>
        <span className={styles.footerTitle}>{"CHÍNH SÁCH BÁN HÀNG"}</span>
        <div className={styles.mainInfo}>
          <ul className={styles.listMenu}>
            <li className={styles.liMenu}>
              <a href="/pages/huong-dan-mua-hang" title="Hướng dẫn mua hàng">
                Hướng dẫn mua hàng
              </a>
            </li>

            <li className={styles.liMenu}>
              <a
                href="/pages/chinh-sach-giao-hang"
                title="Chính sách giao hàng"
              >
                Chính sách giao hàng
              </a>
            </li>

            <li className={styles.liMenu}>
              <a
                href="/pages/chinh-sach-bao-hanh-1"
                title="Chính sách bảo hành"
              >
                Chính sách bảo hành
              </a>
            </li>

            <li className={styles.liMenu}>
              <a
                href="/pages/chinh-sach-thanh-toan"
                title="Chính sách thanh toán"
              >
                Chính sách thanh toán
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className={styles.inforSection}>
        <span className={styles.footerTitle}>{"CHÍNH SÁCH CỬA HÀNG"}</span>
        <div className={styles.mainInfo}>
          <ul className={styles.listMenu}>
            <li className={styles.liMenu}>
              <a href="/pages/huong-dan-mua-hang" title="Chính sách bảo mật">
                Chính sách bảo mật
              </a>
            </li>

            <li className={styles.liMenu}>
              <a
                href="/pages/chinh-sach-giao-hang"
                title="Chính sách khiếu nại"
              >
                Chính sách khiếu nại
              </a>
            </li>

            <li className={styles.liMenu}>
              <a
                href="/pages/chinh-sach-bao-hanh-1"
                title="Chính sách hoàn tiền"
              >
                Chính sách hoàn tiền{" "}
              </a>
            </li>

            <li className={styles.liMenu}>
              <a
                href="/pages/chinh-sach-thanh-toan"
                title="Chính sách Đổi Trả hàng hóa"
              >
                Chính sách Đổi Trả hàng hóa
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className={styles.inforSection}>
        <span className={styles.footerTitle}>{"LIÊN HỆ"}</span>
        <div className={styles.mainInfo}>
          <ul className={styles.listMenu}>
            <li className={styles.liMenu}>
              <a
                href="/pages/huong-dan-mua-hang"
                title="Địa chỉ: 125 Đô Đốc Tuyết - Tp Đà Nẵng"
              >
                Địa chỉ: 125 Đô Đốc Tuyết - Tp Đà Nẵng
              </a>
            </li>

            <li className={styles.liMenu}>
              <a href="/pages/chinh-sach-giao-hang" title="0967 021 221">
                0967 021 221
              </a>
            </li>

            <li className={styles.liMenu}>
              <a href="/pages/chinh-sach-bao-hanh-1" title="0924 00 55 88">
                0924 00 55 88
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}
