import React from "react"
import * as styles from "./top-navigation-item.module.scss"
import { LocationRoute } from "@bep43vn/pages/page-routing"

export const TopNavigationItem = () => {
  return (
    <div>
      <div className={styles.root}>
        <input
          type="checkbox"
          className={styles.navigation__checkbox}
          id="navi-toggle"
        ></input>

        <label htmlFor="navi-toggle" className={styles.navigation__button}>
          <span className={styles.navigation__icon}>&nbsp;</span>
        </label>

        <div className={styles.navigation__background}>&nbsp;</div>

        <nav className={styles.navigation__nav}>
          <ul className={styles.navigation__list}>
            <li className={styles.navigation__item}>
              <div className={styles.asideTitle}>
                <h2 className={styles.titleHead}>
                  <span>Danh mục sản phẩm</span>
                </h2>
              </div>
            </li>
            <li className={styles.navigation__item}>
              <a
                href={LocationRoute.DieuHoa}
                className={styles.navigation__link}
              >
                {"Điều hòa"}
              </a>
            </li>
            <li className={styles.navigation__item}>
              <a
                href={LocationRoute.MayEpTraiCay}
                className={styles.navigation__link}
              >
                {"Máy ép trái cây"}
              </a>
            </li>
            <li className={styles.navigation__item}>
              <a
                href={LocationRoute.MayXaySinhTo}
                className={styles.navigation__link}
              >
                {"Máy xay sinh tố"}
              </a>
            </li>
            <li className={styles.navigation__item}>
              <a
                href={LocationRoute.NoiComDien}
                className={styles.navigation__link}
              >
                {"Nồi cơm điện"}
              </a>
            </li>
            <li className={styles.navigation__item}>
              <a
                href={LocationRoute.BepHutKhoiKhuMui}
                className={styles.navigation__link}
              >
                {"Bếp - Hút khói - Khử mùi"}
              </a>
            </li>
            <li className={styles.navigation__item}>
              <a
                href={LocationRoute.NoiThuyTinhVisions}
                className={styles.navigation__link}
              >
                {"Nồi thủy tinh Visions"}
              </a>
            </li>
            <li className={styles.navigation__item}>
              <a
                href={LocationRoute.NoiMenFujihoro}
                className={styles.navigation__link}
              >
                {"Nồi men Fujihoro"}
              </a>
            </li>
            <li className={styles.navigation__item}>
              <a
                href={LocationRoute.NoiChienKhongDau}
                className={styles.navigation__link}
              >
                {"Nồi chiên không dầu"}
              </a>
            </li>
            <li className={styles.navigation__item}>
              <a href={LocationRoute.Quat} className={styles.navigation__link}>
                {"Quạt"}
              </a>
            </li>
            <li className={styles.navigation__item}>
              <a
                href={LocationRoute.GiaDungKhac}
                className={styles.navigation__link}
              >
                {"Gia dụng khác"}
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  )
}
