import React from "react"
import * as styles from "./main-content.module.scss"
import { MainContentPropsType } from "./main-content.d"
import { ProductCard } from "@bep43vn/windgets/product-card"

export const MainContent: React.FCWithRoute<MainContentPropsType> = props => {
  const { allMarkdownRemark, title = "Thông tin sản phẩm" } = props
  const products = allMarkdownRemark?.edges

  return (
    <div className={styles.root}>
      <h1 className={styles.title}>{title}</h1>

      <div className={styles.productCardList}>
        {products?.map(({ node }) => {
          const title = node.frontmatter.title || node.fields.slug

          return (
            <ProductCard
              title={title}
              price={node.frontmatter.price}
              comparePrice={node.frontmatter.comparePrice}
              imageThumbnail={node.frontmatter.imageThumbnail}
              productSale={node.frontmatter.productSale}
              productDetailHref={node.fields.slug}
              key={node.fields.slug}
            ></ProductCard>
          )
        })}
      </div>
    </div>
  )
}
