import React from "react"
import * as styles from "./master-layout-scene.module.scss"
import {
  AppBarScene,
  TopHeaderScene,
  TopMenuScene,
  BreadCrumbScene,
  SideBarScene,
  FooterScene,
  CopyRightScene,
  TopNavigationScene,
} from "@bep43vn/scenes"
import { BreadCrumbPropsType } from "@bep43vn/scenes"

export type MasterLayoutScenePropsType = BreadCrumbPropsType

export const MasterLayoutScene: React.FCWithRoute<
  BreadCrumbPropsType
> = props => {
  const { collectionHref, collectionName, children } = props
  return (
    <div className={styles.root}>
      <TopNavigationScene></TopNavigationScene>
      <TopHeaderScene></TopHeaderScene>
      <AppBarScene></AppBarScene>
      <TopMenuScene></TopMenuScene>
      <BreadCrumbScene
        collectionName={collectionName}
        collectionHref={collectionHref}
      ></BreadCrumbScene>
      <div className={styles.mainConent}>
        <div className={styles.mainContentWrapper}>
          <div className={styles.mainContentDetail}>
            <SideBarScene></SideBarScene>
            {children}
          </div>
        </div>
        <div className={styles.footer}>
          <div className={styles.footerWrapper}>
            <FooterScene></FooterScene>
          </div>
        </div>
        <div className={styles.footer}>
          <div className={styles.footerWrapper}>
            <CopyRightScene></CopyRightScene>
          </div>
        </div>
      </div>
    </div>
  )
}
