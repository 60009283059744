import React from "react"
import * as styles from "./copyright-scene.module.scss"

export const CopyRightScene = () => {
  return (
    <div className={styles.root}>
      @2022, Bep 43 Da Nang, Hotline: 0967 021 221/0924 00 55 88, Địa chỉ: 125
      Đô Đốc Tuyết - Tp Đà Nẵng
    </div>
  )
}
