import React from "react"
import { Router } from "@yolo-ui"
import {
  DieuHoaPage,
  BepHutKhoiKhuMuiPage,
  NoiThuyTinhVisonsPage,
  NoiMenFurihoroPage,
  QuatPage,
  GiaDungKhacPage,
} from "@bep43vn/pages"
import { NoiChienKhongDauPage } from "./noi-chien-khong-dau"

export const LocationRoute = {
  HomePage: "/",
  NoiThuyTinhCaoCap: "/noi-thuy-tinh-cao-cap",
  DieuHoa: "/dieu-hoa",
  NoiComDien: "/noi-com-dien",
  MayEpTraiCay: "/may-ep-trai-cay",
  MayXaySinhTo: "/may-xay-sinh-to",
  BepHutKhoiKhuMui: "/bep-hut-khoi-khu-mui",
  NoiThuyTinhVisions: "/noi-thuy-tinh-visions",
  NoiMenFujihoro: "/noi-men-fujihoro",
  NoiChienKhongDau: "/noi-chien-khong-dau",
  Quat: "/quat",
  GiaDungKhac: "/gia-dung-khac",
}

export const PageRouting = () => {
  return (
    <Router>
      <NoiThuyTinhVisonsPage path="/"></NoiThuyTinhVisonsPage>
      <DieuHoaPage path={LocationRoute.DieuHoa}></DieuHoaPage>
      <BepHutKhoiKhuMuiPage
        path={LocationRoute.BepHutKhoiKhuMui}
      ></BepHutKhoiKhuMuiPage>
      <NoiThuyTinhVisonsPage
        path={LocationRoute.NoiThuyTinhVisions}
      ></NoiThuyTinhVisonsPage>
      <NoiMenFurihoroPage
        path={LocationRoute.NoiMenFujihoro}
      ></NoiMenFurihoroPage>
      <NoiChienKhongDauPage
        path={LocationRoute.NoiChienKhongDau}
      ></NoiChienKhongDauPage>
      <QuatPage path={LocationRoute.Quat}></QuatPage>
      <GiaDungKhacPage path={LocationRoute.GiaDungKhac}></GiaDungKhacPage>
    </Router>
  )
}
