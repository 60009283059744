import React, { HtmlHTMLAttributes } from "react"
import classNames from "classnames"
import * as styles from "./top-hearder-scene.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPhone, faThumbtack } from "@fortawesome/free-solid-svg-icons"

export const TopHeaderScene: React.FCWithRoute<
  HtmlHTMLAttributes<HTMLDivElement>
> = props => {
  const classes = classNames("top-hearder", styles.root, props.className)
  return (
    <div {...props} className={classes}>
      <span className={styles.company}>Bep 43 Da Nang</span>
      <span className={styles.phone}>
        <FontAwesomeIcon
          icon={faPhone}
          className={styles.phoneIcon}
        ></FontAwesomeIcon>
        Hotline:
        <a className={styles.phoneNumber} href="tel:0967021021">
          {"0967 021 221"}
        </a>
        /
        <a className={styles.phoneNumber} href="tel:0924005588">
          {"0924 00 55 88"}
        </a>
      </span>
      <span className={styles.address}>
        <FontAwesomeIcon
          icon={faThumbtack}
          className={styles.addressIcon}
        ></FontAwesomeIcon>
        Hotline:
        <a className={styles.addressText}>
          {"Địa chỉ: 125 Đô Đốc Tuyết - Tp Đà Nẵng"}
        </a>
      </span>
    </div>
  )
}
