import React from "react"
import * as styles from "./top-menu-scene.module.scss"
// import { MenuMega } from "./menu-mega"
import { HeaderNav } from "./header-nav"

export const TopMenuScene = () => {
  return (
    <div className={styles.root}>
      <div className={styles.container}>
        {/* <MenuMega></MenuMega> */}
        <HeaderNav></HeaderNav>
      </div>
    </div>
  )
}
