import React from "react"
import * as styles from "./breadcrumb-scene.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleRight } from "@fortawesome/free-solid-svg-icons"

export type BreadCrumbPropsType = {
  collectionName?: string
  collectionHref?: string
}

export const BreadCrumbScene: React.FCWithRoute<
  BreadCrumbPropsType
> = props => {
  const { collectionName, collectionHref = "/" } = props

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <ul className={styles.breadcrumb}>
          <li className="home">
            <a href="/">
              <span>{"Trang chủ"}</span>
            </a>
            <span className={styles.breadcrumbAngleRight}>
              <FontAwesomeIcon icon={faAngleRight}></FontAwesomeIcon>
            </span>
          </li>
          <li>
            <a href={collectionHref}>
              <span className={styles.breadcrumbStrongText}>
                {collectionName}
              </span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  )
}
