import React from "react"
import { AsideItem } from "./aside-item"
import * as styles from "./side-bar-scene.module.scss"

export const SideBarScene = () => {
  return (
    <nav className={styles.root}>
      <div className={styles.container}>
        <AsideItem></AsideItem>
        {/* <AsideItem></AsideItem>
        <AsideItem></AsideItem> */}
      </div>
    </nav>
  )
}
