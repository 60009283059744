// extracted by mini-css-extract-plugin
export var root = "top-navigation-item-module--root--hWzwk";
export var navigation__checkbox = "top-navigation-item-module--navigation__checkbox--+GGKW";
export var navigation__button = "top-navigation-item-module--navigation__button--+PosS";
export var navigation__background = "top-navigation-item-module--navigation__background--+0yK+";
export var navigation__nav = "top-navigation-item-module--navigation__nav--XHFe0";
export var navigation__list = "top-navigation-item-module--navigation__list--Kz55U";
export var navigation__item = "top-navigation-item-module--navigation__item--RGk3K";
export var navigation__link = "top-navigation-item-module--navigation__link--eVNAX";
export var navigation__icon = "top-navigation-item-module--navigation__icon--P1lwK";
export var asideTitle = "top-navigation-item-module--asideTitle--TENKo";
export var titleHead = "top-navigation-item-module--titleHead--5DrI5";