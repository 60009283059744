import React from "react"
import { SearchBox } from "./search-box"
import * as styles from "./app-bar-scene.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faShoppingBag, faUser } from "@fortawesome/free-solid-svg-icons"
import { StaticImage } from "gatsby-plugin-image"

export type AppBarPropsType = {
  position?: "top" | "bottom"
  className?: string
}

export const AppBarScene: React.FCWithRoute<AppBarPropsType> = props => {
  const { ...others } = props
  return (
    <header {...others} className={styles.root}>
      <div className={styles.logo}>
        <StaticImage
          src="./logo-bep43.jpg"
          alt="Bep43.vn"
          className={styles.logoText}
        ></StaticImage>
      </div>
      <div className={styles.searchBox}>
        <SearchBox></SearchBox>
      </div>
      <div className={styles.rightSection}>
        <div className={styles.userAccount}>
          <FontAwesomeIcon
            icon={faUser}
            className={styles.userAccountIcon}
          ></FontAwesomeIcon>
          <span className={styles.userAccountText}>Tài khoản</span>
        </div>

        <div className={styles.shoppingBag}>
          <FontAwesomeIcon
            icon={faShoppingBag}
            className={styles.shoppingBagIcon}
          ></FontAwesomeIcon>
          <span className={styles.shoppingBagText}>Giỏ hàng</span>
        </div>
      </div>
    </header>
  )
}
