import React, { useState } from "react"
import * as styles from "./search-box.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSearch } from "@fortawesome/free-solid-svg-icons"

export const SearchBox = () => {
  const [searchValue, setSearchValue] = useState("")
  return (
    <div className={styles.root}>
      <form
        className={styles.searchBar}
        action="/search"
        method="get"
        role="search"
      >
        <input
          className={styles.inputGroupField}
          type="search"
          name="q"
          value={searchValue}
          onChange={e => setSearchValue(e.target.value)}
          placeholder="Tìm kiếm... "
          autoComplete="off"
          required={false}
        />
        <span className={styles.inputGroupButton}>
          <button className={styles.searchButton}>
            <FontAwesomeIcon
              icon={faSearch}
              className={styles.searchButtonIcon}
            ></FontAwesomeIcon>
          </button>
        </span>
      </form>
    </div>
  )
}
