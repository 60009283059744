import React from "react"
import * as styles from "./product-card.module.scss"
import { ProductCardPropsType } from "./product-card.d"

export const ProductCard: React.FCWithRoute<ProductCardPropsType> = props => {
  const {
    title = "-",
    price = "-",
    comparePrice = "-",
    productSale = "-",
    imageThumbnail = "https://product.hstatic.net/1000292693/product/141443757_3182953025137457_665201410040199607_n_2094fa96a348414ebc3f4c859b6b6a21_large.jpg",
    productDetailHref = "/",
  } = props
  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <div className={styles.card}>
          <div className={styles.thumbnail}>
            <a title={title} href={productDetailHref}>
              <img width={"100%"} src={imageThumbnail} alt={title}></img>
            </a>
          </div>
          <div className={styles.productName}>
            <a href={productDetailHref} title={title}>
              {title}
            </a>
          </div>
          <div className={styles.priceBox}>
            {price}
            <span className={styles.comparePrice}>{comparePrice}</span>
            <div className={styles.productSale}>{productSale}</div>
          </div>
        </div>
      </div>
    </div>
  )
}
